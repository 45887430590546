import React from 'react';
import classNames from './loaderDots.module.scss';

function LoaderDots({ zoom }) {
  return (
    <div className={classNames.ldsDefault} style={{ zoom }}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

export default LoaderDots;
