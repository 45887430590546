import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import classNames from './bondDetailModal.module.scss';
import { FormatCurrency, FormatNumber } from '../../utils/FunctionTools';
import LoginModal from '../LoginModalNew';
import { BankContext } from '../../context/Context';
import { useUserApps, useUserDetails, useUserVaults } from '../../queryHooks';
import LoadingAnim from '../LoadingAnim/LoadingAnim';
import { useAppContextDetails } from '../../context/AppContext';

async function registerOnApp(email) {
  const { data } = await axios.post(
    'https://comms.globalxchange.io/gxb/apps/register/user',
    {
      email: email,
      app_code: 'ice',
    }
  );
  return data;
}

async function createBond(params, app_code) {
  const { data } = await axios.post(
    'https://comms.globalxchange.io/coin/iced/contract/create',
    {
      ...params,
      origin_app_code: app_code,
    }
  );
  return data;
}

function BondDetailModal({ onClose = () => {}, data }) {
  const { email, token, tostShowOn } = useContext(BankContext);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [bondCount, setBondCount] = useState(1);
  const [appSelected, setAppSelected] = useState();
  const [iceApp, setIceApp] = useState();
  const [coinSelected, setCoinSelected] = useState();

  const { mutate: registerApp } = useMutation(registerOnApp, {});
  const { appCode } = useAppContextDetails();

  const { mutate: mutateCreateBonds, isLoading } = useMutation(createBond, {
    onSuccess: (data) => {
      if (data.status) {
        setStep(4);
      } else {
        tostShowOn(data.message || 'Some Thing Went Wrong!');
      }
    },
  });

  const getStep = useCallback(() => {
    switch (step) {
      case 1:
        return (
          <SelectBondCount
            email={email}
            setStep={setStep}
            data={data}
            bondCount={bondCount}
            setBondCount={setBondCount}
          />
        );
      case 2:
        return (
          <SelectAppFrom
            email={email}
            setStep={setStep}
            data={data}
            setAppSelected={setAppSelected}
            appSelected={appSelected}
            setIceApp={setIceApp}
          />
        );
      case 3:
        return (
          <SelectCoinFrom
            email={email}
            setStep={setStep}
            data={data}
            setCoinSelected={setCoinSelected}
            coinSelected={coinSelected}
            appSelected={appSelected}
            onSubmit={() => {
              mutateCreateBonds(
                {
                  email,
                  token,
                  profile_id: iceApp.profile_id,
                  coin: data?.coin?.coinsData[0]?.coin,
                  days: data?.coin?.days,
                  num_of_bonds: bondCount,
                  simulate: false,
                  payCoin: coinSelected?.coinSymbol,
                  stats: false,
                  bond_type: 'custom',
                  bond_template_id: data?.coin?.bond_template_id,
                  debitfromApp: appSelected?.app_code,
                  debitfromProfileId: appSelected?.profile_id,
                },
                appCode
              );
            }}
          />
        );
      case 4:
        return <ShowSuccess onClose={onClose} data={data} />;
      default:
        return (
          <BondDetail
            email={email}
            setLoginModalOpen={setLoginModalOpen}
            setStep={setStep}
            data={data}
            registerApp={registerApp}
          />
        );
    }
  }, [step, bondCount, email, appSelected, coinSelected, data]);

  return (
    <>
      {loginModalOpen ? (
        <LoginModal
          onClose={() => setLoginModalOpen(false)}
          onSuccess={(email) => {
            setLoginModalOpen(false);
            registerApp(email);
            setStep(1);
          }}
        />
      ) : (
        <div className={classNames.bondDetailModal}>
          <div
            className={classNames.overlayClose}
            onClick={() => {
              try {
                onClose();
              } catch (error) {}
            }}
          />
          <div className={classNames.settingsCard}>
            {getStep()}
            {isLoading && (
              <div className={classNames.loadingAnim}>
                <LoadingAnim />
                <div className={classNames.loadingText}>
                  Creating {bondCount} Bond{bondCount > 1 ? 's' : ''}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

function BondDetail({ email, setLoginModalOpen, setStep, data, registerApp }) {
  return (
    <>
      <div className={classNames.inCard}>
        <div
          className={classNames.topBar}
          style={{ backgroundColor: `#${data?.banker?.colorCode}` }}
        ></div>
        <div className={classNames.logoWrap}>
          <img src={data?.banker?.profilePicURL} alt="" />
        </div>
        <div className={classNames.title}>{data?.banker?.displayName}</div>
        <div className={classNames.subTitle}>
          {data?.banker?.country} | {FormatNumber(data?.coin?.days)} Day Bond
        </div>
        <div className={classNames.listItems}>
          <div className={classNames.listItem}>
            <div className={classNames.label}>Bond Asset</div>
            <div className={classNames.value}>
              <img src={data?.coinOb?.icon} alt="" />
              {data?.coinOb?.coin}
            </div>
          </div>
          <div className={classNames.listItem}>
            <div className={classNames.label}>Cost</div>
            <div className={classNames.value}>
              {FormatCurrency(
                data?.coin?.coinsData[0]?.bondCost,
                data?.coin?.coinsData[0]?.coin
              )}
            </div>
          </div>
          <div className={classNames.listItem}>
            <div className={classNames.label}>Gross Term ROI</div>
            <div className={classNames.value}>
              {FormatNumber(data?.coin?.days * data?.coin?.daily_interest_rate)}
              %
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.footerBtns}>
        <div className={classNames.btnAssets} style={{ opacity: 0.4 }}>
          Asset Page
        </div>
        <div
          className={classNames.btnQuickBuy}
          style={{ backgroundColor: `#${data?.banker?.colorCode}` }}
          onClick={() => {
            if (email) {
              setStep(1);
              registerApp(email);
            } else setLoginModalOpen(true);
          }}
        >
          Quick Buy
        </div>
      </div>
    </>
  );
}

function SelectBondCount({ email, setStep, data, bondCount, setBondCount }) {
  const { data: userData } = useUserDetails(email);
  return (
    <>
      <div className={`${classNames.inCard} ${classNames.profile}`}>
        <img src={userData?.profile_img} alt="" className={classNames.dp} />
        <div className={classNames.name}>{userData?.name}</div>
        <div className={classNames.email}>{userData?.email}</div>
        <div className={classNames.title}>
          How Many Bonds Do You Want To Buy?
        </div>
        <div className={classNames.bonds}>
          <div className={classNames.inpRow}>
            <input
              type="number"
              className={classNames.input}
              value={bondCount}
              onChange={(e) => {
                setBondCount(e.target.value);
              }}
            />
            <span>Bonds</span>
          </div>
          <div className={classNames.inpRow}>
            <span>
              {FormatCurrency(bondCount * data?.coin?.coinsData[0]?.bondCost)}
            </span>
            <span>{data?.coin?.coinsData[0]?.coin}</span>
          </div>
        </div>
      </div>
      <div className={classNames.footerBtns}>
        <div className={classNames.btnAssets} onClick={() => setStep(0)}>
          Back
        </div>
        <div
          className={classNames.btnQuickBuy}
          style={{ backgroundColor: `#${data?.banker?.colorCode}` }}
          onClick={() => setStep(2)}
        >
          Next
        </div>
      </div>
    </>
  );
}

function SelectAppFrom({
  email,
  setStep,
  data,
  setAppSelected,
  appSelected,
  setIceApp,
}) {
  const { data: userData } = useUserDetails(email);
  const { data: userApps = [], isLoading: userAppsLoading } =
    useUserApps(email);
  useEffect(() => {
    const app = userApps?.filter((app) => app?.app_code === 'ice');
    setIceApp(app[0]);
  }, [userApps]);

  const [search, setSearch] = useState('');
  return (
    <>
      <div className={`${classNames.inCard} ${classNames.profile}`}>
        <img src={userData?.profile_img} alt="" className={classNames.dp} />
        <div className={classNames.name}>{userData?.name}</div>
        <div className={classNames.email}>{userData?.email}</div>
        <input
          type="text"
          className={classNames.search}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Select The App You Want To Purchase From..."
        />
        <div className={classNames.appList}>
          {userAppsLoading
            ? Array(10)
                .fill('')
                .map((_, i) => (
                  <Skeleton
                    className={classNames.appCard}
                    width={140}
                    height={140}
                  />
                ))
            : userApps
                .filter(
                  (app) =>
                    app?.app_name
                      ?.toLowerCase()
                      .includes(search.toLowerCase()) ||
                    app?.app_code?.toLowerCase().includes(search.toLowerCase())
                )
                .map((app) => (
                  <div
                    className={`${classNames.appCard} ${
                      classNames[
                        (app?.app_code === appSelected?.app_code).toString()
                      ]
                    }`}
                    key={app?.app_code}
                    onClick={() => setAppSelected(app)}
                  >
                    <img src={app.app_icon} alt="" />
                    <span>{app.app_name}</span>
                  </div>
                ))}
        </div>
      </div>
      <div className={classNames.footerBtns}>
        <div className={classNames.btnAssets} onClick={() => setStep(1)}>
          Back
        </div>
        <div
          className={classNames.btnQuickBuy}
          style={{ backgroundColor: `#${data?.banker?.colorCode}` }}
          onClick={() => setStep(3)}
        >
          Next
        </div>
      </div>
    </>
  );
}

function SelectCoinFrom({
  email,
  setStep,
  data,
  setCoinSelected,
  coinSelected,
  appSelected,
  onSubmit,
}) {
  const { appCurrencyName } = useAppContextDetails();
  const { data: userData } = useUserDetails(email);
  const { data: vaultsList = [], isLoading: vaultListLoading } = useUserVaults(
    email,
    undefined,
    appSelected?.app_code,
    true,
    true,
    appCurrencyName
  );

  const [search, setSearch] = useState('');
  return (
    <>
      <div className={`${classNames.inCard} ${classNames.profile}`}>
        <img src={userData?.profile_img} alt="" className={classNames.dp} />
        <div className={classNames.name}>{userData?.name}</div>
        <div className={classNames.email}>{userData?.email}</div>
        <input
          type="text"
          className={classNames.search}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Select The Vault That You Want To Pay With"
        />
        <div className={classNames.appList}>
          {vaultListLoading
            ? Array(10)
                .fill('')
                .map((_, i) => (
                  <Skeleton
                    key={i}
                    className={classNames.appCard}
                    width={140}
                    height={140}
                  />
                ))
            : vaultsList
                .filter(
                  (vault) =>
                    vault?.coinSymbol
                      ?.toLowerCase()
                      ?.includes(search.toLowerCase()) ||
                    vault?.coinName
                      ?.toLowerCase()
                      ?.includes(search.toLowerCase())
                )
                .map((coin) => (
                  <div
                    className={`${classNames.appCard} ${
                      classNames[
                        (
                          coin?.coinSymbol === coinSelected?.coinSymbol
                        ).toString()
                      ]
                    }`}
                    key={coin?.coinSymbol}
                    onClick={() => setCoinSelected(coin)}
                  >
                    <img src={coin.coinImage} alt="" />
                    <span>{coin.coinName}</span>
                    <div className={classNames.value}>
                      {FormatCurrency(coin.coinValue, coin.coinSymbol)}
                    </div>
                  </div>
                ))}
        </div>
      </div>
      <div className={classNames.footerBtns}>
        <div className={classNames.btnAssets} onClick={() => setStep(2)}>
          Back
        </div>
        <div
          className={classNames.btnQuickBuy}
          style={{ backgroundColor: `#${data?.banker?.colorCode}` }}
          onClick={() => onSubmit()}
        >
          Next
        </div>
      </div>
    </>
  );
}

function ShowSuccess({ onClose, data }) {
  return (
    <>
      <div className={classNames.inCard}>
        <div className={classNames.successText}>Bond Creation Success</div>
      </div>
      <div className={classNames.footerBtns}>
        <div className={classNames.btnAssets} onClick={() => {}}>
          View Bond Hash
        </div>
        <div
          className={classNames.btnQuickBuy}
          style={{ backgroundColor: `#${data?.banker?.colorCode}` }}
          onClick={() => onClose()}
        >
          Close
        </div>
      </div>
    </>
  );
}

export default BondDetailModal;
