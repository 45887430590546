import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';

const Arc = ({
  data,
  index,
  createArc,
  colors,
  onMouseOver,
  onMouseOut,
  segment,
}) => (
  <g key={index} className="arc">
    <path
      opacity={segment === index || segment === null ? 1 : 0.1}
      onMouseEnter={() => onMouseOver(index)}
      onMouseLeave={() => onMouseOut()}
      className="arc"
      d={createArc(data)}
      fill={data.data.color ? data.data.color : colors(index)}
    />
  </g>
);

// eslint-disable-next-line object-curly-newline
const DonutChart = ({ onMouseOver, onMouseOut, pieData, segment }) => {
  const innerRadius = 135;
  const outerRadius = 150;
  const colors = d3.scaleOrdinal(d3.schemeCategory10);
  const [data, setData] = useState([]);
  const createArc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);
  useEffect(() => {
    const createPie = d3
      .pie()
      .value((d) => d.value)
      .sort(null);
    setData(createPie(pieData));
  }, [pieData]);

  return (
    <svg viewBox="0 0 300 300" style={{ width: '100%', height: '100%' }}>
      <g transform={`translate(${outerRadius} ${outerRadius})`}>
        {data.map((d, i) => (
          <Arc
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            data={d}
            index={i}
            createArc={createArc}
            colors={colors}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onFocus={onMouseOver}
            onBlur={onMouseOut}
            segment={segment}
          />
        ))}
      </g>
    </svg>
  );
};

export default DonutChart;
