import React from 'react';
import { useMarketContext } from '../../context/MarketContext';

export const Overlay = () => {
  const { setStreamOpen } = useMarketContext();
  return (
    <div
      style={{
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        background: 'rgba(0,0,0,0.8)',
        zIndex: 4,
        transition: 'all 400ms ease',
      }}
      onClick={() => setStreamOpen(false)}
    />
  );
};
