import React, { useContext, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from './selectBondAssetModal.module.scss';
import doubleTik from '../../static/images/clipIcons/doubleTik.svg';
import { useCustomBondsList } from '../../queryHooks';
import { BankContext } from '../../context/Context';

function SelectBondAssetModal({
  onClose = () => {},
  onSuccess = () => {},
  bondAsset,
  setBondAsset,
}) {
  const { email } = useContext(BankContext);
  const { data: bondsData, isLoading } = useCustomBondsList(email);
  const [search, setSearch] = useState('');
  return (
    <div className={classNames.selectBondAssetModal}>
      <div
        className={classNames.overlayClose}
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
        }}
      />
      <div className={classNames.settingsCard}>
        <div className={classNames.inCard}>
          <div className={classNames.title}>Select A Bond Asset</div>
          <div className={classNames.search}>
            <input
              type="text"
              placeholder="Search Possible Bond Asset..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className={classNames.settingCards}>
            <div
              className={`${classNames.settingCard} ${
                classNames[bondAsset.coin === undefined]
              }`}
              onClick={() => {
                setBondAsset({
                  name: 'All Bond Assets ',
                  coin: undefined,
                  icon: doubleTik,
                });
                onSuccess();
              }}
            >
              <img src={doubleTik} alt="" className={classNames.icon} />
              <div className={classNames.texts}>
                All Assets
                <span>{bondsData?.count} Bonds</span>
              </div>
            </div>
            {isLoading
              ? Array(4)
                  .fill('')
                  .map((_, i) => (
                    <div className={classNames.settingCard}>
                      <Skeleton className={classNames.icon} circle />
                      <div className={classNames.texts}>
                        <Skeleton className={classNames.icon} width={200} />
                      </div>
                    </div>
                  ))
              : bondsData?.currenciesData &&
                Object.values(bondsData?.currenciesData)
                  .filter((coin) =>
                    coin?.name?.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((coin) => (
                    <div
                      className={`${classNames.settingCard} ${
                        classNames[bondAsset.coin === coin.coin]
                      }`}
                      onClick={() => {
                        setBondAsset(coin);
                        onSuccess();
                      }}
                    >
                      <img src={coin.icon} alt="" className={classNames.icon} />
                      <div className={classNames.texts}>
                        {coin.name}
                        <span>{coin?.count} Bonds</span>
                      </div>
                    </div>
                  ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectBondAssetModal;
